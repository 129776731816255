<template>
  <div class="bg">
    <div class="wrapper">
      <div class="clearfix">
        <div _ngcontent-c14="" class="about_body_left">
          <dl _ngcontent-c14="">
            <dt _ngcontent-c14="">联系我们</dt>
          </dl>
        </div>
        <div _ngcontent-c14="" class="about_body_right" id="Contact">
          <div _ngcontent-c14="">
            <div _ngcontent-c14="" class="zc_ean_content">
              <p _ngcontent-c14="" class="article_title"><span _ngcontent-c14="">联系我们</span></p>
              <div _ngcontent-c14="" class="articl_content" style="padding-top:10px;height:800px;">
                <p _ngcontent-c14="" style="font-size: 14px;line-height: 40px;">
                  <strong _ngcontent-c14="">联系人：</strong>孙老师 18800122129 &nbsp;  张老师 15230642917
                </p>
                <p _ngcontent-c14="" style="font-size: 14px;line-height: 40px;">
                  <strong _ngcontent-c14="">固定电话：</strong>010-61593835/1784/1957(传真）
                </p>
                <p _ngcontent-c14="" style="font-size: 14px;line-height: 40px;">
                  <strong _ngcontent-c14="">公众号：</strong>交通行业网络安全攻防训练基地
                </p>
                <p _ngcontent-c14="" style="font-size: 14px;line-height: 40px;">
                  <strong _ngcontent-c14="">地址：</strong>北京东燕郊开发区行宫西大街交通运输部管理干部学院
                </p>
                <p _ngcontent-c14="" style="font-size: 14px;line-height: 40px;">
                  <strong _ngcontent-c14="">邮编：</strong>101601
                </p>
                <p _ngcontent-c14="">
                  <img _ngcontent-c14="" alt="" src="../../assets/images/djt/address.png">
                </p>
              </div>
            </div>
          </div>

        </div>
  </div>
    </div>
  </div>
  
</template>

<style lang="less" scoped>
.about_body_right[_ngcontent-c14]{
    width: 948px;
    height: 100%;
    float: left;
  margin-bottom: 2rem;

}
.about_body_right[_ngcontent-c14]   .article_title[_ngcontent-c14]{
		height:75px;
		width:945px;
		border:1px solid #c2c2c2;

	}
  .zc_ean_content[_ngcontent-c14]   .article_title[_ngcontent-c14]   span[_ngcontent-c14]{
			display:block;
			height:73px;
			width:74px;
			line-height:73px;
			border-bottom:3px solid #217aff;
			text-align:center;
			color:#217aff;
			margin-left:40px;
			font-size:18px;
		}
    .about_body_right[_ngcontent-c14]   .articl_content[_ngcontent-c14]{
      height:377px;
      width:947px;
      border:1px solid #c2c2c2;
      margin-top:20px;
      padding:30px 130px 30px 30px;
    }
  .about_body_right[_ngcontent-c14]   .articl_content[_ngcontent-c14]   .content_title[_ngcontent-c14]{
      height:35px;
      line-height:35px;
      font-size:14px;
      font-weight:bold;
    }
  .about_body_right[_ngcontent-c14]   .articl_content[_ngcontent-c14]   p[_ngcontent-c14]{
      line-height:1.5;
    }
  .about_body_right[_ngcontent-c14]   .articl_content[_ngcontent-c14]   .strong[_ngcontent-c14]{
      display:inline-block;
      height:40px;
      line-height:40px;
      font-size:14px;
      font-weight:bold;
    }











.zc_ean_content[_ngcontent-c14]   .article_title1[_ngcontent-c14]   span[_ngcontent-c14]{
			width:120px;
		}
  .about_body_left{
    width: 230px;
    height: 680px;
	  float: left;
    font-size:14px;
}
.about_body_left dl {
		text-align: center;
	    height: 409px;
	}
.about_body_left dt {
		height:48px;
		line-height:48px;
		width:203px;
		border: 1px solid #c2c2c2;
		border-top:none;
		cursor: pointer;
	}
.about_body_left  dt {
		background: #217aff;
		color:#fff;
	}
  .left {
    margin: 20px 0;
    font-size: 22px;
  }
  .em2[_ngcontent-c6]{
  text-indent: 2em;
  line-height: 40px;
}
.center[_ngcontent-c6]{
  text-align: center;
}
.img {
  width: 100%;
  display: flex;
  padding: 10px 0;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 600px;
}
  .bg {
    color: #333;
    font-size: 16px;
    background: #F7F6FA;
    padding: 60px 0;
    .activity {
      background: #fff;
      padding: 20px;
    }
  }
  .text-title {
    font-size: 24px;
    font-weight: bold;
    color:#333;
    text-align: center;
    margin-bottom: 18px;

  }
  .title {
    margin-right: 7px;
    font-size: 18px;
    color: #217aff;
    h6 {
      font-size: 22px;
  margin-right: 7px;

    }
  }
  .intro {
    text-indent: 2em;
  line-height: 40px;
  font-size: 16px;
  color: #333;

  }
  
  
  
  </style>
